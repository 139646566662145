export type AppWebItemType = 'app' | 'web' | 'unknown';

export function getAppWebType(text: string): AppWebItemType {
  // Check for app extensions
  if (/\.(app|exe)$/i.test(text)) {
    return 'app';
  }

  // Check for valid URL or domain
  // Passing it to the URL object, it will throw an error if it's invalid
  try {
    new URL(text);
    return 'web';
  } catch (e) {
    // If it's neither an app nor a web URL
    throw new Error(
      `Invalid input: "${text}" is neither a valid app file nor a web URL/domain. Be sure to add protocol (https) if it's a URL.`,
    );
  }
}
