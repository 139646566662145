import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import LineStyleOutlinedIcon from '@mui/icons-material/LineStyleOutlined';

import FiltersBoard, { ProductivityFilterParams } from './FiltersBoard';
import DataTable from '../../components/DataTable/DataTable';
import MainContainer from '../../components/MainContainer';
import PageTitle from '../../components/Title/PageTitle';
import { M3Button } from '../../components/M3/M3Button';
import DocumentTitle from '../../components/DocumentTitle';
import InfiniteScrollListener from '../../components/InfiniteScrollListener';
import { UserActivitySkeleton } from '../../components/User/UserSkeleton';
import WindowScrollTop from '../../components/WindowScrollTop';
import UserTimeline from '../UserDetail/UserTimeline/UserTimeline';

import { ProductivityAgentUser } from './types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import { useInfinite } from '../../hooks/global/useInfinite';
import {
  useUserLastActive,
  UseUserLastActiveProps,
} from '../../hooks/go2-agent/user-status';
import { useUserProvider } from '../../providers/user/user';
import ActivityChart from '../Activities/ActivityChart';
import { useMetadataProvider } from '../../providers/metadata/metadata';
import { useTimezone } from '../../components/Util/Timezone';
import { getWeekRangeStartsInMonday } from '../../utils/date';

type Props = {};

const agentFieldLabelMap: {
  [K in keyof Partial<ProductivityAgentUser>]: string;
} = {
  user_id: '',
  division_id: 'Division',
};

const agentFieldStyleMap: {
  [K in keyof Partial<ProductivityAgentUser>]: CSSProperties;
} = {
  user_id: {
    flex: 1,
  },
};

const defaultAgentFields: (keyof ProductivityAgentUser)[] = ['user_id'];
const defaultActiveField: keyof ProductivityAgentUser = 'id';

const Users = (props: Props) => {
  const { getUser, setUniqueIdsToFetch } = useUserProvider();
  const { metabase } = useMetadataProvider();
  const timezone = useTimezone();

  const [filters, setFilters] = useState<{
    active: keyof ProductivityAgentUser;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);

  const [selectedFields, setSelectedFields] = useState<
    (keyof ProductivityAgentUser)[]
  >([]);

  const userTimelineRefProps = useRef<any>({});

  const handleColumnItem = useCallback(
    (field: keyof ProductivityAgentUser, item: ProductivityAgentUser) => {
      if (field === 'user_id') {
        let user = getUser('user_id', item.user_id);
        let date = moment(moment(item.date.split('T')[0]));

        return (
          <Box style={{ paddingTop: 8 }}>
            <Box display='flex' style={{ marginBottom: 12 }}>
              <UserActivitySkeleton
                user={user}
                linkProps={{
                  to: `/users/${user?.id}/activity`,
                  state: {
                    item,
                    user,
                  },
                }}
              />
            </Box>
            <Box style={{ margin: '0 -16px' }}>
              <UserTimeline
                fetchOnVisibleOnly
                userId={item.user_id}
                withNavigationButtons={false}
                selectedFilteredDate={date}
                {...userTimelineRefProps.current}
              />
            </Box>
          </Box>
        );
      }

      return item[field] ?? <span style={{ opacity: 0.2 }}>–</span>;
    },
    [getUser, userTimelineRefProps],
  );

  const handleHeaderColumnItem = useCallback(
    (field: keyof ProductivityAgentUser) => {
      let value =
        agentFieldLabelMap[field] ?? toTitleCase(stripToFormatterWord(field));
      return value;
    },
    [],
  );

  const handleOnHeaderColumnClick = useCallback(
    (field: keyof ProductivityAgentUser, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );

  const handleOnFieldsChange = useCallback(
    (newFields: (keyof ProductivityAgentUser)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }
    },
    [filters, setSelectedFields, handleOnHeaderColumnClick],
  );

  /*
  const handleRowClick = useCallback(
    (item: AgentUser) => {
      navigate(`/users/${item.id}/activity`, {
        state: {
          item,
        },
      });
    },
    [navigate],
  );
  */

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<ProductivityFilterParams>(
    () => ({
      start_date: getWeekRangeStartsInMonday(moment()).start,
      end_date: getWeekRangeStartsInMonday(moment()).end,
      users: [],
      divisions: [],
    }),
  );
  const updateFilterParams = useCallback(
    (params: Partial<ProductivityFilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // User Agent Status ----------------------------------------------------------------
  const limit = 10;
  const agentStatusQueryParams: Partial<UseUserLastActiveProps> = {
    start_date: filterParams.start_date.format('YYYY-MM-DD'),
    end_date: filterParams.end_date.format('YYYY-MM-DD'),
    user_ids: filterParams.users.map((u) => u.id).join(),
    division_ids: filterParams.divisions.map((div) => div.id).join(),
  };
  const infiniteUserAgentStatus = useInfinite<
    ProductivityAgentUser,
    UseUserLastActiveProps
  >({
    keyFieldName: 'refId',
    skipFetchOnInit: true,
    useQuery: useUserLastActive,
    queryParams: {
      user_ids: agentStatusQueryParams.user_ids,
      division_ids: agentStatusQueryParams.division_ids,
      start_date: agentStatusQueryParams.start_date!,
      end_date: agentStatusQueryParams.end_date!,
      limit: limit,
    },
    transformData(item: ProductivityAgentUser) {
      // Let's create an identifier for the entry since it will multiple
      // users, with different dates
      item.refId = `${item.user_id}_${moment(item.date.split('T')[0]).format(
        'YYYY-MM-DD',
      )}`;
      return item;
    },
  });

  // Charts --------------------------------------------------------------------
  let activityChartParams = useMemo(
    () => ({
      start_date: filterParams.start_date.format('YYYY-MM-DD'),
      end_date: filterParams.end_date.format('YYYY-MM-DD'),
      division: filterParams.divisions.map((d) => d.label),
      user_id: filterParams.users.map((u) => u.id.toString()),
      timezone,
    }),
    [
      timezone,
      filterParams.start_date,
      filterParams.end_date,
      filterParams.users,
      filterParams.divisions,
    ],
  );

  /**
   * User timeline props reference. To be used inside handleItem
   */
  userTimelineRefProps.current = {
    filterParams: filterParams,
  };

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: infiniteUserAgentStatus.data.map((d) => d.user_id),
    });
  }, [infiniteUserAgentStatus.data, setUniqueIdsToFetch]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: filterParams.users.map((d) => d.id),
    });
  }, [filterParams.users, setUniqueIdsToFetch]);

  useEffect(() => {
    infiniteUserAgentStatus.reset({
      emptyList: true,
    });
    // eslint-disable-next-line
  }, [filterParams]);

  return (
    <>
      <DocumentTitle title='Productivity' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <PageTitle
            title='Productivity'
            icon={<LineStyleOutlinedIcon style={{ fontSize: 34 }} />}
          />
          <Stack direction='row' gap={2}>
            {/* <M3Button color='secondary'>Use Saved Filters</M3Button> */}
            <M3Button
              active={isFiltersVisible}
              onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            >
              <FilterAltOutlinedIcon />
              {isFiltersVisible ? 'Hide' : 'Show'} Filters
            </M3Button>
          </Stack>
        </Box>
        <br />
        <Box display={isFiltersVisible ? undefined : 'none'}>
          <FiltersBoard
            filterParams={filterParams}
            selectedFields={selectedFields}
            allFields={defaultAgentFields}
            fieldLabelMap={agentFieldLabelMap}
            onFieldsChange={handleOnFieldsChange}
            updateFilterParams={updateFilterParams}
          />
        </Box>
        <Box
          gap={4}
          display='flex'
          flexWrap='wrap'
          style={{
            marginTop: 14,
          }}
        >
          <ActivityChart
            title='Top Idle'
            metabaseProps={{
              url: '/embed/question/',
              key: 'question',
              id: metabase.charts.idle_time.top_members,
              params: activityChartParams,
            }}
          />
          <ActivityChart
            title='Top Activity %'
            metabaseProps={{
              url: '/embed/question/',
              key: 'question',
              id: metabase.charts.activity_interval.top_active,
              params: activityChartParams,
            }}
          />
          <ActivityChart
            title='Least Activity %'
            metabaseProps={{
              url: '/embed/question/',
              key: 'question',
              id: metabase.charts.activity_interval.least_active,
              params: activityChartParams,
            }}
          />
        </Box>
        <Typography component='div'>
          <DataTable
            orderBy={filters.order}
            fields={selectedFields.length ? selectedFields : defaultAgentFields}
            data={infiniteUserAgentStatus.data}
            fieldColumnStyleMap={agentFieldStyleMap}
            handleColumnItem={handleColumnItem}
            handleHeaderColumnItem={handleHeaderColumnItem}
          />
        </Typography>
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => infiniteUserAgentStatus.loadNext()}
      />
      <WindowScrollTop />
    </>
  );
};

export default Users;
