import React, {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import {
  Box,
  CircularProgress,
  Divider,
  FormLabel,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import { M3TextField } from '../../M3/M3TextField';
import M3SurfaceContainer from '../../M3/M3SurfaceContainer';
import { M3Button } from '../../M3/M3Button';
import BasicForm, { useForm } from '../../BasicForm';
import AppBreadcrumbs from '../../AppBreadcrumbs';
import FormPanel from '../../Panels/FormPanel';
import AutocompleteSearch, {
  AutocompleteSearchOptionItem,
} from '../../Filters/AutocompleteSearch';
import { useAutocompleteUserSearch } from '../../Filters/AutocompleteUserSearch';
import ConfirmationDialog, {
  useConfirmationDialog,
} from '../../Dialogs/ConfirmationDialog';

import { IterableObject, ReactRenderElement } from '../../../types/types';
import { getAppWebType } from '../../../utils/settings';
import { AppWebExceptionItem } from '../../../types/settings';
import {
  Go2AgentAppWebsiteWhitelistItem,
  Go2AgentAppWebsiteWhitelistItemDetail,
} from '../../../hooks/cosmos/go2-agent/app-website-whitelist.type';
import { useAuthProvider } from '../../../providers/auth/auth';
import { getConfigWithAuthorization } from '../../../services/base';
import { request as cosmosRequest } from '../../../services/cosmos';
import { useAppProvider } from '../../../providers/app/app';
import {
  useAdminDivisions,
  UseAdminDivisionsProps,
} from '../../../hooks/divisions';
import { useUserSearch } from '../../../hooks/profile';
import { ListResult } from '../../../types/response';
import { useAppWebsiteWhitelistById } from '../../../hooks/cosmos/go2-agent/app-website-whitelist';
import { escapeRegExp } from '../../../utils/string';

type ApplicationsAndWebsitesSettingsProps = {
  isGlobal?: boolean;
  userId?: number | null;
  isFetchingAppWebsiteData?: boolean;
  appUserAppWebsiteWhitelist?: ListResult<Go2AgentAppWebsiteWhitelistItem>;
  appUserAppWebsiteWhitelistRefetch: () => void;
  webUserAppWebsiteWhitelist?: ListResult<Go2AgentAppWebsiteWhitelistItem>;
  webUserAppWebsiteWhitelistRefetch: () => void;
};

export type TabButtonParamItem = {
  name: string;
  icon?: ReactRenderElement;
  label: ReactRenderElement;
};

function cleanUpPayloadData(payload: Partial<Go2AgentAppWebsiteWhitelistItem>) {
  const fields: (keyof Go2AgentAppWebsiteWhitelistItem)[] = [
    'id',
    'uuid',
    'type',
    'text',
    'is_default',
  ];

  return fields.reduce((prev, key) => {
    if (key in payload) {
      prev[key] = payload[key];
    }
    return prev;
  }, {} as IterableObject);
}

const ApplicationsAndWebsitesSettings = ({
  isGlobal,
  userId,
  isFetchingAppWebsiteData,
  appUserAppWebsiteWhitelist,
  appUserAppWebsiteWhitelistRefetch,
  webUserAppWebsiteWhitelist,
  webUserAppWebsiteWhitelistRefetch,
}: ApplicationsAndWebsitesSettingsProps) => {
  const [activeAppWebItem, setActiveAppWebItem] = useState<
    | null
    | Go2AgentAppWebsiteWhitelistItem
    | Go2AgentAppWebsiteWhitelistItemDetail
  >(null);

  const { data: updatedActiveAppWebItem } = useAppWebsiteWhitelistById(
    { id: activeAppWebItem?.id! },
    { enabled: !!activeAppWebItem },
  );
  let currentActiveWebAppItem = updatedActiveAppWebItem || activeAppWebItem;

  const renderForm = () => {
    return (
      <>
        <Box>
          <Typography
            gap={1}
            component='h5'
            fontSize={18}
            fontWeight={500}
            display='flex'
            alignItems='center'
          >
            Applications & Websites
          </Typography>
          <Typography component='div' fontSize={14} style={{ opacity: 0.5 }}>
            All applications and websites are monitored by default. Use this
            section to specify exceptions.
          </Typography>
        </Box>
        <br />
        <SettingsForm
          isGlobal={isGlobal}
          userId={userId}
          setActiveAppWebItem={setActiveAppWebItem}
          isFetchingAppWebsiteData={isFetchingAppWebsiteData}
          appUserAppWebsiteWhitelist={appUserAppWebsiteWhitelist}
          appUserAppWebsiteWhitelistRefetch={appUserAppWebsiteWhitelistRefetch}
          webUserAppWebsiteWhitelist={webUserAppWebsiteWhitelist}
          webUserAppWebsiteWhitelistRefetch={webUserAppWebsiteWhitelistRefetch}
        />
      </>
    );
  };

  const renderMetadata = () => {
    return (
      <Stack
        gap={6}
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        style={{ fontSize: 18, opacity: 0.8 }}
      >
        <Typography
          component='div'
          fontSize='inherit'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <GroupOutlinedIcon style={{ fontSize: 22 }} />
          &nbsp;
          {currentActiveWebAppItem?.users.length ?? 0}
        </Typography>
        <Typography
          component='div'
          fontSize='inherit'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <LanOutlinedIcon style={{ fontSize: 17 }} />
          &nbsp;
          {currentActiveWebAppItem?.divisions.length ?? 0}
        </Typography>
      </Stack>
    );
  };

  const renderEditForm = () => {
    return (
      <>
        <Box display='flex' style={{ marginTop: -12, marginBottom: 12 }}>
          <AppBreadcrumbs
            items={[
              {
                id: 'apps_websites',
                label: 'Applications & Websites',
                onClick: () => setActiveAppWebItem(null),
              },
              {
                id: currentActiveWebAppItem!.id.toString(),
                label: currentActiveWebAppItem!.text,
              },
            ]}
          />
        </Box>
        <Box display='flex' alignItems='center' mt={2} mb={2}>
          <Typography
            flex={1}
            component='h5'
            fontSize={18}
            fontWeight={500}
            display='flex'
            alignItems='center'
          >
            {currentActiveWebAppItem!.type === 'app' ? (
              <WidgetsOutlinedIcon style={{ fontSize: 22, marginRight: 8 }} />
            ) : (
              <LanguageOutlinedIcon style={{ fontSize: 22, marginRight: 8 }} />
            )}
            {currentActiveWebAppItem!.text ?? ' '}
          </Typography>
          {renderMetadata()}
        </Box>
        <EditSettingsDetailForm
          isGlobal={isGlobal}
          userId={userId}
          activeAppWebItem={currentActiveWebAppItem}
          setActiveAppWebItem={setActiveAppWebItem}
          isFetchingAppWebsiteData={isFetchingAppWebsiteData}
          appUserAppWebsiteWhitelist={appUserAppWebsiteWhitelist}
          appUserAppWebsiteWhitelistRefetch={appUserAppWebsiteWhitelistRefetch}
          webUserAppWebsiteWhitelist={webUserAppWebsiteWhitelist}
          webUserAppWebsiteWhitelistRefetch={webUserAppWebsiteWhitelistRefetch}
        />
      </>
    );
  };

  useEffect(() => {
    return () => {
      setActiveAppWebItem(null);
    };
  }, [setActiveAppWebItem]);

  return <Box>{activeAppWebItem ? renderEditForm() : renderForm()}</Box>;
};

type SettingsFormProps = Partial<ApplicationsAndWebsitesSettingsProps> & {
  setActiveAppWebItem: Dispatch<
    SetStateAction<
      | null
      | Go2AgentAppWebsiteWhitelistItem
      | Go2AgentAppWebsiteWhitelistItemDetail
    >
  >;
};
const SettingsForm = ({
  userId,
  isFetchingAppWebsiteData,
  appUserAppWebsiteWhitelist,
  appUserAppWebsiteWhitelistRefetch,
  webUserAppWebsiteWhitelist,
  webUserAppWebsiteWhitelistRefetch,
  setActiveAppWebItem,
}: SettingsFormProps) => {
  const { getTokenSilently } = useAuthProvider();

  const [text, setText] = useState('');
  const [error, setError] = useState<null | Error>();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleOnSubmitAppWeb = useCallback(
    async (value: Go2AgentAppWebsiteWhitelistItem) => {
      try {
        setIsProcessing(true);
        setError(null);

        let httpConf = getConfigWithAuthorization(await getTokenSilently());
        let payload = cleanUpPayloadData({
          ...value,
        });

        await cosmosRequest.post(
          `/api/go2-agent/app-website-whitelist/`,
          payload,
          httpConf,
        );

        setText('');
        appUserAppWebsiteWhitelistRefetch?.();
        webUserAppWebsiteWhitelistRefetch?.();
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsProcessing(false);
      }
    },
    [
      getTokenSilently,
      setIsProcessing,
      setError,
      appUserAppWebsiteWhitelistRefetch,
      webUserAppWebsiteWhitelistRefetch,
      setText,
    ],
  );

  const handleOnKeyDownForAppsWebsites = useCallback(
    (evt: KeyboardEvent<HTMLElement>) => {
      if (isProcessing) return;

      let value = (evt.target as HTMLInputElement).value.trim();
      let key = evt.code;

      if (value && (key === 'Enter' || key === 'Comma')) {
        try {
          let type = getAppWebType(value);
          let item: AppWebExceptionItem = {
            uuid: uuid(),
            type,
            text: value,
          };
          handleOnSubmitAppWeb(item as Go2AgentAppWebsiteWhitelistItem);
        } catch (e) {
          setError(e as Error);
        }
      }
    },
    [setError, isProcessing, handleOnSubmitAppWeb],
  );

  const { handleSubmit } = useForm({});
  const onSubmitNoOp = handleSubmit(() => {});

  const renderAppsWebsiteItem = (item: Go2AgentAppWebsiteWhitelistItem) => {
    return (
      <Typography
        key={item.uuid}
        component='div'
        fontSize={15}
        display='flex'
        alignItems='center'
        style={{
          padding: '2px 0 2px 2px',
          userSelect: 'none',
        }}
      >
        <Box flex={1} display='flex' alignItems='center' flexWrap='nowrap'>
          <Typography
            component='div'
            display='flex'
            alignItems='center'
            onClick={() => setActiveAppWebItem(item)}
            style={{
              width: 0,
              flexGrow: 2,
              paddingRight: 30,
              cursor: 'pointer',
              overflowWrap: 'anywhere',
            }}
            sx={{
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            {item.text}
            {item.is_default && (
              <PublicOutlinedIcon
                style={{
                  fontSize: 13,
                  opacity: 0.4,
                  marginLeft: 8,
                }}
              />
            )}
          </Typography>
          <Stack
            gap={2}
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            style={{
              fontSize: 14,
              opacity: 0.8,
              width: 100,
              minWidth: 100,
            }}
          >
            <span style={{ display: 'flex', alignItems: 'center', width: 50 }}>
              <GroupOutlinedIcon style={{ fontSize: 16 }} />
              &nbsp;
              {item.users.length}
            </span>
            <span style={{ display: 'flex', alignItems: 'center', width: 50 }}>
              <LanOutlinedIcon style={{ fontSize: 14 }} />
              &nbsp;
              {item.divisions.length}
            </span>
          </Stack>
        </Box>
      </Typography>
    );
  };

  const renderColumnPanelTitle = (
    title: ReactRenderElement,
    total: number,
    icon: ReactRenderElement,
  ) => {
    return (
      <Typography
        mb={1}
        component='div'
        fontSize={15}
        fontWeight={500}
        display='flex'
        alignItems='center'
      >
        {icon}
        &nbsp;&nbsp;{title}
        <span style={{ opacity: 0.5, marginLeft: 6 }}>({total})</span>
      </Typography>
    );
  };

  useEffect(() => {
    appUserAppWebsiteWhitelistRefetch?.();
    webUserAppWebsiteWhitelistRefetch?.();
  }, [appUserAppWebsiteWhitelistRefetch, webUserAppWebsiteWhitelistRefetch]);

  return (
    <Box>
      <M3SurfaceContainer elevation={1}>
        <Typography
          component='div'
          fontSize={14}
          textAlign='center'
          style={{
            opacity: 0.8,
            marginTop: 4,
            padding: '16px 16px',
          }}
        >
          Specify apps and websites to exclude from monitoring.
          <br /> Use app names (e.g. Messages.app, Notepad.exe) or full URLs
          (e.g., www.facebook.com).
        </Typography>
      </M3SurfaceContainer>
      <BasicForm
        onSubmit={onSubmitNoOp}
        style={{ position: 'relative', marginBottom: 32, marginTop: 18 }}
      >
        <M3TextField
          fullWidth
          placeholder='Type apps or websites...'
          value={text}
          onKeyDown={handleOnKeyDownForAppsWebsites}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          ) => setText(evt.target.value)}
        />
        <Box
          style={{
            top: 0,
            right: 8,
            bottom: 0,
            opacity: 0.5,
            margin: 'auto',
            display: 'flex',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isProcessing ? (
            <CircularProgress size={18} style={{ marginRight: 3 }} />
          ) : (
            <AddCircleOutlinedIcon />
          )}
        </Box>
      </BasicForm>
      {!!error && (
        <FormLabel
          error
          style={{ fontSize: 12, marginTop: -20, display: 'block' }}
        >
          {error.message}
        </FormLabel>
      )}
      <Stack gap={6} direction='column' mt={2} flex={1}>
        <Box>
          {renderColumnPanelTitle(
            'Applications',
            appUserAppWebsiteWhitelist?.count ?? 0,
            <WidgetsOutlinedIcon style={{ fontSize: 20 }} />,
          )}
          {appUserAppWebsiteWhitelist?.results
            .filter((v) =>
              text ? new RegExp(escapeRegExp(text), 'i').test(v.text) : true,
            )
            .map((item) => renderAppsWebsiteItem(item))}
        </Box>
        <Box>
          {renderColumnPanelTitle(
            'Websites',
            webUserAppWebsiteWhitelist?.count ?? 0,
            <LanguageOutlinedIcon style={{ fontSize: 20 }} />,
          )}
          {webUserAppWebsiteWhitelist?.results
            .filter((v) =>
              text ? new RegExp(escapeRegExp(text), 'i').test(v.text) : true,
            )
            .map((item) => renderAppsWebsiteItem(item))}
        </Box>
      </Stack>
      <br />
    </Box>
  );
};
type EditSettingsDetailFormProps =
  Partial<ApplicationsAndWebsitesSettingsProps> & {
    activeAppWebItem:
      | Go2AgentAppWebsiteWhitelistItem
      | Go2AgentAppWebsiteWhitelistItemDetail
      | null;
    setActiveAppWebItem: Dispatch<
      SetStateAction<
        | null
        | Go2AgentAppWebsiteWhitelistItem
        | Go2AgentAppWebsiteWhitelistItemDetail
      >
    >;
  };
const EditSettingsDetailForm = ({
  userId,
  isGlobal,
  activeAppWebItem,
  setActiveAppWebItem,
  isFetchingAppWebsiteData,
  appUserAppWebsiteWhitelist,
  appUserAppWebsiteWhitelistRefetch,
  webUserAppWebsiteWhitelist,
  webUserAppWebsiteWhitelistRefetch,
}: EditSettingsDetailFormProps) => {
  const { isDarkMode } = useAppProvider();
  const { getTokenSilently } = useAuthProvider();
  const appWebId = activeAppWebItem?.id;

  const { refetch } = useAppWebsiteWhitelistById({ id: appWebId! });

  const handleOnUpdateAppWeb = useCallback(
    async (value: Partial<Go2AgentAppWebsiteWhitelistItem>) => {
      try {
        let httpConf = getConfigWithAuthorization(await getTokenSilently());
        let payload = cleanUpPayloadData({
          ...value,
        });

        await cosmosRequest.patch(
          `/api/go2-agent/app-website-whitelist/${appWebId}/`,
          payload,
          httpConf,
        );

        refetch();
        appUserAppWebsiteWhitelistRefetch?.();
        webUserAppWebsiteWhitelistRefetch?.();
      } catch (e) {}
    },
    [
      appWebId,
      getTokenSilently,
      refetch,
      appUserAppWebsiteWhitelistRefetch,
      webUserAppWebsiteWhitelistRefetch,
    ],
  );

  const removeConfirmationDialog = useConfirmationDialog();
  const globalConfirmationDialog = useConfirmationDialog();

  const handleOnDeleteAppWebWhitelistItem = useCallback(async () => {
    try {
      let httpConf = getConfigWithAuthorization(await getTokenSilently());

      await cosmosRequest.delete(
        `/api/go2-agent/app-website-whitelist/${appWebId}/`,
        httpConf,
      );

      setActiveAppWebItem(null);
    } catch (e) {
    } finally {
      removeConfirmationDialog.confirm.setIsOpen(false);
    }
    // eslint-disable-next-line
  }, [appWebId, getTokenSilently, setActiveAppWebItem]);

  const handleOnMakeVisibleAppWebWhitelistItem = useCallback(async () => {
    handleOnUpdateAppWeb({
      is_default: !activeAppWebItem?.is_default,
    });
    globalConfirmationDialog.confirm.setIsOpen(false);
    // eslint-disable-next-line
  }, [
    activeAppWebItem,
    handleOnUpdateAppWeb,
    globalConfirmationDialog.confirm.setIsOpen,
  ]);

  const renderMetadata = () => {
    return (
      <>
        <Stack
          direction='row'
          justifyContent='space-between'
          style={{ marginTop: 8 }}
        >
          {/* <Typography
          display='flex'
          component='div'
          alignItems='center'
          style={{
            fontSize: 14,
            marginLeft: -8,
          }}
        >
          <Checkbox size='small' />
          <span style={{ opacity: 0.8 }}>Make it available to all users</span>
        </Typography> */}
          <M3Button
            style={{ marginLeft: -11 }}
            onClick={() => globalConfirmationDialog.confirm.setIsOpen(true)}
          >
            <span style={{ position: 'relative', marginLeft: -1 }}>
              <PublicOutlinedIcon style={{ marginTop: 6 }} />
            </span>
            Make it {activeAppWebItem?.is_default ? 'un' : ''}available to all
            users
            {activeAppWebItem?.is_default && (
              <CheckOutlinedIcon
                style={{
                  fontSize: 16,
                  marginLeft: 8,
                  marginRight: -4,
                }}
              />
            )}
            &nbsp;
          </M3Button>
          {renderDeleteForm()}
        </Stack>
        <ConfirmationDialog
          {...globalConfirmationDialog.confirm}
          title={
            (activeAppWebItem?.is_default ? 'Remove ' : 'Add ') +
            `Global Exception`
          }
          message={
            activeAppWebItem?.is_default
              ? `Are you sure you want to remove "${activeAppWebItem?.text}" as a global exception?`
              : `Are you sure you want to add "${activeAppWebItem?.text}" as a global exception?`
          }
          onConfirm={handleOnMakeVisibleAppWebWhitelistItem}
        />
      </>
    );
  };

  const renderDeleteForm = () => {
    return (
      <>
        <Stack direction='row' position='relative'>
          <Box flex={1} />
          <Box
            display='flex'
            alignItems='center'
            // style={{ position: 'absolute', right: -8, top: 0 }}
          >
            <Typography
              component='div'
              fontSize={12}
              style={{
                opacity: 0.5,
                paddingRight: 4,
              }}
            >
              Delete this exception?
            </Typography>
            <M3Button
              style={{
                color: isDarkMode
                  ? 'var(--md-ref-palette-error80)'
                  : 'var(--md-ref-palette-error40)',
              }}
              onClick={() => removeConfirmationDialog.confirm.setIsOpen(true)}
            >
              <DeleteOutlinedIcon
                style={{
                  color: 'inherit',
                  fontSize: 20,
                  marginRight: 4,
                }}
              />
              Delete
            </M3Button>
          </Box>
        </Stack>
        <ConfirmationDialog
          {...removeConfirmationDialog.confirm}
          title={`Delete ${
            activeAppWebItem?.type === 'app' ? 'Application' : 'Website'
          } Exception`}
          message={`Are you sure you want to delete "${activeAppWebItem?.text}" as an exception?`}
          onConfirm={handleOnDeleteAppWebWhitelistItem}
        />
      </>
    );
  };

  const renderNotice = () => {
    return (
      <M3SurfaceContainer elevation={1}>
        <Typography
          component='div'
          fontSize={14}
          textAlign='center'
          style={{
            opacity: 0.8,
            marginTop: 4,
            padding: '16px 16px',
          }}
        >
          Choose specific users or entire divisions for targeted exception
          applications or websites.
          <br />
          Alternatively, check <strong>Make available to all users</strong> to
          apply the exception system-wide.
        </Typography>
      </M3SurfaceContainer>
    );
  };

  return (
    <Box>
      <Divider style={{ borderWidth: 1 }} />
      {renderMetadata()}
      <br />
      {renderNotice()}
      <br />
      <AppWebListAssociations activeAppWebItem={activeAppWebItem} />
    </Box>
  );
};

type AppWebListAssociationsProps = {
  activeAppWebItem:
    | Go2AgentAppWebsiteWhitelistItem
    | Go2AgentAppWebsiteWhitelistItemDetail
    | null;
};
const AppWebListAssociations = ({
  activeAppWebItem,
}: AppWebListAssociationsProps) => {
  const { getTokenSilently } = useAuthProvider();
  const appWebId = activeAppWebItem?.id;

  const { refetch } = useAppWebsiteWhitelistById({ id: appWebId! });

  // Users ---------------------------------------------------------------------
  const autocompleteUserSearch = useAutocompleteUserSearch();
  const handleOnUserSearchSelected = useCallback(
    async (user: any) => {
      if (!user) return;

      try {
        let httpConf = getConfigWithAuthorization(await getTokenSilently());

        await cosmosRequest.post(
          `/api/go2-agent/app-website-whitelist/${appWebId}/add-users/`,
          { user_ids: [user.id] },
          httpConf,
        );

        refetch();
      } catch (e) {}
    },
    // eslint-disable-next-line
    [appWebId, refetch],
  );
  const handleOnRemoveUserAssociated = useCallback(
    async (item: Go2AgentAppWebsiteWhitelistItemDetail['users'][0]) => {
      try {
        let httpConf = getConfigWithAuthorization(await getTokenSilently());

        await cosmosRequest.post(
          `/api/go2-agent/app-website-whitelist/${appWebId}/remove-users/`,
          { user_ids: [item.id] },
          httpConf,
        );

        refetch();
      } catch (e) {
        console.error(e);
      }
    },
    [appWebId, refetch, getTokenSilently],
  );

  // Divisions -----------------------------------------------------------------
  const getDivisionsOptionFormatter = useCallback(
    (div: any) =>
      ({
        id: div.id,
        label: div.name,
        props: div,
      } as AutocompleteSearchOptionItem),
    [],
  );
  const handleOnDivisionSearchSelected = useCallback(
    async (division: any) => {
      if (!division) return;

      try {
        let httpConf = getConfigWithAuthorization(await getTokenSilently());

        await cosmosRequest.post(
          `/api/go2-agent/app-website-whitelist/${appWebId}/add-divisions/`,
          { division_ids: [division.id] },
          httpConf,
        );

        refetch();
      } catch (e) {}
    },
    [appWebId, refetch, getTokenSilently],
  );
  const handleOnRemoveDivisionAssociated = useCallback(
    async (item: Go2AgentAppWebsiteWhitelistItemDetail['divisions'][0]) => {
      try {
        let httpConf = getConfigWithAuthorization(await getTokenSilently());

        await cosmosRequest.post(
          `/api/go2-agent/app-website-whitelist/${appWebId}/remove-divisions/`,
          { division_ids: [item.id] },
          httpConf,
        );

        refetch();
      } catch (e) {
        console.error(e);
      }
    },
    [appWebId, refetch, getTokenSilently],
  );

  const renderAssociatedItems = (
    items:
      | Go2AgentAppWebsiteWhitelistItem['users']
      | Go2AgentAppWebsiteWhitelistItem['divisions']
      | Go2AgentAppWebsiteWhitelistItemDetail['users']
      | Go2AgentAppWebsiteWhitelistItemDetail['divisions'],
    onClick: (
      item:
        | Go2AgentAppWebsiteWhitelistItemDetail['users'][0]
        | Go2AgentAppWebsiteWhitelistItemDetail['divisions'][0],
    ) => void,
  ) => {
    return (
      <Box style={{ paddingTop: 12 }}>
        {items.map((d) => {
          return (
            <Box
              key={typeof d === 'number' ? d : d.id}
              style={{ padding: '2px 0' }}
            >
              {typeof d === 'number' ? (
                <Skeleton />
              ) : (
                <Typography
                  component='div'
                  display='flex'
                  alignItems='center'
                  fontSize={15}
                  style={{ userSelect: 'none' }}
                >
                  <span style={{ paddingLeft: 4, flex: 1 }}>{d.name}</span>
                  <RemoveCircleOutlineOutlinedIcon
                    style={{
                      fontSize: 14,
                      opacity: 0.5,
                      cursor: 'pointer',
                    }}
                    onClick={() => onClick(d)}
                  />
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <>
      <Stack
        gap={4}
        direction='row'
        style={{
          minHeight: 400,
        }}
      >
        <FormPanel
          label={
            <Stack direction='row' justifyContent='space-between'>
              <span>Name</span>
              <Typography
                component='div'
                fontSize='inherit'
                style={{ display: 'flex', alignItems: 'center', opacity: 0.8 }}
              >
                <GroupOutlinedIcon style={{ fontSize: 20 }} />
                &nbsp;
                {activeAppWebItem?.users.length ?? 0}
              </Typography>
            </Stack>
          }
          style={{ flex: 1 }}
        >
          <AutocompleteSearch
            clearOnSelected
            withSearchIcon
            searchKey='s'
            placeholder='Search name...'
            onSelect={handleOnUserSearchSelected}
            optionFormatter={autocompleteUserSearch.getUserOptionFormatter}
            onSearchTextOptions={autocompleteUserSearch.onUserSearchTextOptions}
            renderOptionItem={autocompleteUserSearch.getRenderUserOptionItem}
            useInfiniteProps={{
              isFlatResult: true,
              useQuery: useUserSearch as any,
            }}
          />
          {renderAssociatedItems(
            activeAppWebItem?.users ?? [],
            handleOnRemoveUserAssociated,
          )}
        </FormPanel>
        <FormPanel
          label={
            <Stack direction='row' justifyContent='space-between'>
              <span>Division</span>
              <Typography
                component='div'
                fontSize='inherit'
                style={{ display: 'flex', alignItems: 'center', opacity: 0.8 }}
              >
                <LanOutlinedIcon style={{ fontSize: 17 }} />
                &nbsp;
                {activeAppWebItem?.divisions.length ?? 0}
              </Typography>
            </Stack>
          }
          style={{ flex: 1 }}
        >
          <AutocompleteSearch
            clearOnSelected
            searchKey='name'
            placeholder='Search division...'
            onSelect={handleOnDivisionSearchSelected}
            optionFormatter={getDivisionsOptionFormatter}
            useInfiniteProps={{
              useQuery: useAdminDivisions,
              queryParams: {
                status: 'active',
              } as UseAdminDivisionsProps,
            }}
          />
          {renderAssociatedItems(
            activeAppWebItem?.divisions ?? [],
            handleOnRemoveDivisionAssociated,
          )}
        </FormPanel>
      </Stack>
    </>
  );
};

export default ApplicationsAndWebsitesSettings;
